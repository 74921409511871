import React from 'react'

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons"

const SocialMediaArticle = ({ title, url }) => (
    <div className="social-media-article">
        <span className="social-media-article-share pb-3">Partager</span>
        <div className="d-flex justify-content-center">
            <FacebookShareButton
                url={url}
                resetButtonStyle={false}
            >
                <FontAwesomeIcon icon={faFacebookF} size="lg" />
            </FacebookShareButton>
            <LinkedinShareButton
                url={url}
                title={title}
                source={url}
                resetButtonStyle={false}

            >
                <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
            </LinkedinShareButton>
            <TwitterShareButton
                url={url}
                title={title}
                resetButtonStyle={false}

            >
                <FontAwesomeIcon icon={faTwitter} size="lg" />
            </TwitterShareButton>
        </div>
    </div>
)

export default SocialMediaArticle