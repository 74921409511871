import React from "react"
import TitleBlock from "./blocks/TitleBlock";
import Layout from "../components/layout"
import Blocks from "./blocks/Blocks"
import SEO from "../components/seo"
import SliderPage from "../components/SliderPage"
import { Link } from "gatsby"
import AbstractMedia from '../components/abstractMedia/AbstractMedia'
import SocialMediaArticle from '../components/SocialMediaArticle'

import "../styles/main.scss"

const Article = props => {
    let { article, media, allArticles } = props.pageContext;
    let { title, titleSEO, backgroundImage, decoration, date, blocks, category, url, descriptionSEO } = article
    allArticles = allArticles.filter(a => a.category === category && a.id !== article.id)
    allArticles.slice(0, 6)
    let page = {};
    page.blocks = blocks ? blocks.sort((a, b) => a.ordre - b.ordre) : [];
    page.url = props.url;
    page.ariane = [
        { title: "Accueil", url: "/" },
        { title: "Blog", url: "/blog" },
        { title: title, url: page.url },
    ]

    switch (category) {
        case "VIE_ENTREPRISE":
            category = 'Vie de l\'entreprise'
            break;
        case "TECHNIQUE":
            category = 'Technique'
            break;
        default:
            category = 'Clients'
            break;
    }

    return (
        <Layout ariane={page.ariane}>
            <SEO titleSEO={titleSEO} description={descriptionSEO} />
            <div className="article">
                <TitleBlock media={media} data={{ title: "", backgroundImage }} />
                <div className="container">
                    <Link to="/blog" className="button" state={{ category: category }}>{category}</Link>
                    <div className="roof">
                        {decoration && (<AbstractMedia media={media} abstractMedia={decoration} title={(title && title !== "") && title} className="top" />)}
                        <div className="content-article">
                            <div className="date">{date.split("-").reverse().join("/")}</div>
                            <h1>{title}</h1>
                        </div>
                    </div>
                </div>
                <Blocks page={page} media={media} />
                <div className="bottom-page">
                    <div className="container">
                        <div className="text-center">
                            <SocialMediaArticle title={title} url={`${props.location.origin}/blog/${url}`} />
                            <a href="/blog" className="go-back">RETOUR AU BLOG</a>
                        </div>
                    </div>
                </div>
                {allArticles.length !== 0 && (<SliderPage title={`Autres articles`} baseUrl={`/blog/`} items={allArticles} media={media} />)}
            </div>
        </Layout>
    )
}

export default Article