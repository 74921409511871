import React from 'react'
import { Carousel } from 'react-bootstrap'
import AbstractMedia from "./abstractMedia/AbstractMedia"
import { useMediaQuery } from 'react-responsive'

const SliderPage = ({ title, baseUrl, items, media }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 })
    let elementsJSX = []
    let elements = []

    if (isMobile) {
        items.forEach(item => {
            elementsJSX.push(
                <Carousel.Item key={`only-${item.id}`}>
                    <div className="row justify-content-md-center">
                        <div className="col-md-10">
                            <a href={baseUrl + item.url} className="achievement-slider-item">
                                {item.media && (<AbstractMedia media={media} abstractMedia={item.media} title={(item.title && item.title !== "") && item.title} className="img-fluid d-block mx-auto" />)}
                                <h5>{item.title}</h5>
                            </a>
                        </div>
                    </div>
                </Carousel.Item>
            )
        })
    } else {
        while (items.length !== 0) {
            elements = items.slice(0, 3);
            let counter = 1;
            elementsJSX.push(
                <Carousel.Item key={`multiple-${counter++}`}>
                    <div className="row justify-content-md-center">
                        {elements.map(element => (
                            <div className="col-md-4" key={`multi-${element.id}`}>
                                <a href={baseUrl + element.url} className="achievement-slider-item">
                                    {element.media && (<AbstractMedia media={media} abstractMedia={element.media} title={(element.title && element.title !== "") && element.title} className="img-fluid d-block mx-auto" />)}
                                    <h5>{element.title}</h5>
                                </a>
                            </div>
                        ))}
                    </div>
                </Carousel.Item>
            )
            for (let i = 0; i < elements.length; i++) {
                items.shift()
            }
        }
    }

    return (
        <div className="achievement-slider-page block">
            <h2>{title}</h2>
            <Carousel indicators={false}>
                {elementsJSX}
            </Carousel>
        </div>
    )
}

export default SliderPage